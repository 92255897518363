export const ADD_TRACK_FROM_URL = "ADD_TRACK_FROM_URL";
export const CLOSE_WINAMP = "CLOSE_WINAMP";
export const OPEN_WINAMP = "OPEN_WINAMP";
export const MINIMIZE_WINAMP = "MINIMIZE_WINAMP";
export const IS_PLAYING = "IS_PLAYING";
export const IS_STOPPED = "IS_STOPPED";
export const PAUSE = "PAUSE";
export const PLAY = "PLAY";
export const SEEK_TO_PERCENT_COMPLETE = "SEEK_TO_PERCENT_COMPLETE";
export const SET_BALANCE = "SET_BALANCE";
export const SET_BAND_VALUE = "SET_BAND_VALUE";
export const SET_FOCUS = "SET_FOCUS";
export const SET_BAND_FOCUS = "SET_BAND_FOCUS";
export const SET_FOCUSED_WINDOW = "SET_FOCUSED_WINDOW";
export const SET_MEDIA = "SET_MEDIA";
export const SET_SCRUB_POSITION = "SET_SCRUB_POSITION";
export const SET_SKIN_DATA = "SET_SKIN_DATA";
export const SET_VOLUME = "SET_VOLUME";
export const START_WORKING = "START_WORKING";
export const STEP_MARQUEE = "STEP_MARQUEE";
export const STOP = "STOP";
export const STOP_WORKING = "STOP_WORKING";
export const TOGGLE_DOUBLESIZE_MODE = "TOGGLE_DOUBLESIZE_MODE";
export const SET_EQ_AUTO = "SET_EQ_AUTO";
export const SET_EQ_ON = "SET_EQ_ON";
export const SET_EQ_OFF = "SET_EQ_OFF";
export const TOGGLE_LLAMA_MODE = "TOGGLE_LLAMA_MODE";
export const TOGGLE_REPEAT = "TOGGLE_REPEAT";
export const TOGGLE_SHUFFLE = "TOGGLE_SHUFFLE";
export const TOGGLE_TIME_MODE = "TOGGLE_TIME_MODE";
export const TOGGLE_VISUALIZER_STYLE = "TOGGLE_VISUALIZER_STYLE";
export const UNSET_FOCUS = "UNSET_FOCUS";
export const UPDATE_TIME_ELAPSED = "UPDATE_TIME_ELAPSED";
export const SET_USER_MESSAGE = "SET_USER_MESSAGE";
export const UNSET_USER_MESSAGE = "UNSET_USER_MESSAGE";
export const SET_PLAYLIST_SCROLL_POSITION = "SET_PLAYLIST_SCROLL_POSITION";
export const CLICKED_TRACK = "CLICKED_TRACK";
export const CTRL_CLICKED_TRACK = "CTRL_CLICKED_TRACK";
export const SHIFT_CLICKED_TRACK = "SHIFT_CLICKED_TRACK";
export const SELECT_ALL = "SELECT_ALL";
export const SELECT_ZERO = "SELECT_ZERO";
export const INVERT_SELECTION = "INVERT_SELECTION";
export const REMOVE_ALL_TRACKS = "REMOVE_ALL_TRACKS";
export const CROP_TRACKS = "CROP_TRACKS";
export const FILE_INFO = "FILE_INFO";
export const REMOVE_TRACKS = "REMOVE_TRACKS";
export const SET_AVAILABLE_SKINS = "SET_AVAILABLE_SKINS";
export const REVERSE_LIST = "REVERSE_LIST";
export const RANDOMIZE_LIST = "RANDOMIZE_LIST";
export const SET_TRACK_ORDER = "SET_TRACK_ORDER";
export const PLAY_TRACK = "PLAY_TRACK";
export const BUFFER_TRACK = "BUFFER_TRACK";
export const DRAG_SELECTED = "DRAG_SELECTED";
export const SET_MEDIA_TAGS = "SET_MEDIA_TAGS";
export const SET_MEDIA_DURATION = "SET_MEDIA_DURATION";
export const TOGGLE_WINDOW = "TOGGLE_WINDOW";
export const CLOSE_WINDOW = "CLOSE_WINDOW";
export const MEDIA_TAG_REQUEST_INITIALIZED = "MEDIA_TAG_REQUEST_INITIALIZED";
export const MEDIA_TAG_REQUEST_FAILED = "MEDIA_TAG_REQUEST_FAILED";
export const NETWORK_CONNECTED = "NETWORK_CONNECTED";
export const NETWORK_DISCONNECTED = "NETWORK_DISCONNECTED";
export const UPDATE_WINDOW_POSITIONS = "UPDATE_WINDOW_POSITIONS";
export const WINDOW_SIZE_CHANGED = "WINDOW_SIZE_CHANGED";
export const TOGGLE_WINDOW_SHADE_MODE = "TOGGLE_WINDOW_SHADE_MODE";
export const LOADED = "LOADED";
export const SET_Z_INDEX = "SET_Z_INDEX";
export const DISABLE_MARQUEE = "DISABLE_MARQUEE";
export const SET_DUMMY_VIZ_DATA = "SET_DUMMY_VIZ_DATA";
export const SET_WINDOW_VISIBILITY = "SET_WINDOW_VISIBILITY";
export const LOADING = "LOADING";
export const CLOSE_REQUESTED = "CLOSE_REQUESTED";
export const LOAD_SERIALIZED_STATE = "LOAD_SERIALIZED_STATE";
export const RESET_WINDOW_SIZES = "RESET_WINDOW_SIZES";
export const BROWSER_WINDOW_SIZE_CHANGED = "BROWSER_WINDOW_SIZE_CHANGED";
export const LOAD_DEFAULT_SKIN = "LOAD_DEFAULT_SKIN";
export const ENABLE_MEDIA_LIBRARY = "ENABLE_MEDIA_LIBRARY";
export const ENABLE_MILKDROP = "ENABLE_MILKDROP";
export const SET_MILKDROP_DESKTOP = "SET_MILKDROP_DESKTOP";
export const SET_VISUALIZER_STYLE = "SET_VISUALIZER_STYLE";
export const GOT_BUTTERCHURN_PRESETS = "GOT_BUTTERCHURN_PRESETS";
export const GOT_BUTTERCHURN = "GOT_BUTTERCHURN";
export const RESOLVE_PRESET_AT_INDEX = "RESOLVE_PRESET_AT_INDEX";
export const SELECT_PRESET_AT_INDEX = "SELECT_PRESET_AT_INDEX";
export const TOGGLE_PRESET_OVERLAY = "TOGGLE_PRESET_OVERLAY";
export const PRESET_REQUESTED = "PRESET_REQUESTED";
export const TOGGLE_RANDOMIZE_PRESETS = "TOGGLE_RANDOMIZE_PRESETS";
export const TOGGLE_PRESET_CYCLING = "TOGGLE_PRESET_CYCLING";
export const SCHEDULE_MILKDROP_MESSAGE = "SCHEDULE_MILKDROP_MESSAGE";
export const SET_MILKDROP_FULLSCREEN = "SET_MILKDROP_FULLSCREEN";
